import axios from 'axios'
import { getToken } from '@/utils/setToken.js'
import { Message } from 'element-ui'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js'

const service = axios.create({
    baseURL: '/', // baseURL会自动加在请求地址上
    // timeout: 10000
})

// 添加请求拦截器
service.interceptors.request.use((config) => {
    // 在请求之前做些什么(获取并设置token)
    config.headers['token'] = getToken('token')
    return config
}, (error) => {
    return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
    // 对响应数据做些什么
    let { code, msg } = response.data
    if(code !== 200) {
        // Message({msg: msg || 'error', type: 'warning'})
		if(code == 403){
			router.push('/login')
		}
    }
    return response
}, (error) => {
    return Promise.reject(error)
})

export default service